$mainFont: GTAmerica,'Inter', sans-serif

$red: #C8102E
$green: #16BE4F
$darkGrey: #F3F3F3
$outline: #D2D1D0

$lokalYellow: #F5EED4

$header-height: 70px

$break375: 375px
$break30: 30rem
$break48: 48rem
$break62: 62rem
$break75: 75rem
$break100: 100rem
